import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import {buildBreadcrumbsItem} from '../helpers'
import styles from '../scss/page.scss'
import Loader from "../components/loader/loader";

class HelpCenter extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Honest Paws - Help Center'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Honest Paws - Help Center'
      },
      children: [],
      disallowIndexing: true
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('Help Center', this.props.location.href)
    ]
    this.loaderTitle = ["While you wait, here is a pun... What do you call a dog magician? A ", <b key='help-center-bold'>labrabacadadbrador!</b>]
    this.state = {
      isLoading: true
    }
    this.endLoad = this.endLoad.bind(this)
  }

  componentDidMount() {
    const _this = this
    window.gladlyHCConfig = { 
      api: 'https://onepet.gladly.com', 
      orgId: 'N74Esjt1TrCCavOrXgeZIA', 
      brandId: 'Im-jeOALRUuiKsI67qrmVw', 
      cdn: 'https://cdn.gladly.com', 
      selector: '#gladly-help-center' 
    };
    function l () {
      var t = document,
        e = t.createElement('script');
      (e.type = 'text/javascript'), (e.async = !0), (e.src = 'https://cdn.gladly.com/help-center/hcl.js'), (e.onload = _this.endLoad);
      var a = t.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(e, a);
    }
    l()
  }

  endLoad() {
    this.setState({
      isLoading: false
    })
  };

  render() {
    return (
      <Layout containerClassName={`page page-help-center`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Loader style={ {display: this.state.isLoading ? 'block' : 'none'} } loadertitle={this.loaderTitle}/>
        <section className="bg-primary help-center-section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="text-white">How Can We Help?</h1>
                <div className="get-in-touch-info mt-3">Call/Text us: <a href="tel:1-844-729-7790">1-844-729-7790</a><br/>
                  Email us: <a href="mailto:ask@honestpaws.com">ask@honestpaws.com</a><br/>
                  Monday - Friday 8AM - 8PM CST</div>
                <a href="https://www.honestpaws.com/contact-us/" target="_top" className="btn btn-white-secondary mt-3" >Get In Touch</a>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div id="gladly-help-center"></div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}

export default HelpCenter

export const HelpCenterQuery = graphql`
  query HelpCenterQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`